import { useState, useEffect } from "react";

function ArticleDossier({ saveChanges, color, showExplainer, explainerText }) {
  const [selectedColor, setSelectedColor] = useState(color);
  const colors = [
    { value: "yellow", title: "Geel" },
    { value: "purple", title: "Paars" },
    { value: "lightblue", title: "Lichtblauw" },
    { value: "pink", title: "Roze" },
    { value: "blue", title: "Blauw" },
  ];
  useEffect(() => {
    if (color === null || color === undefined || color === "") {
      setSelectedColor(colors[Math.floor(Math.random() * colors.length)]);
    } else {
      setSelectedColor(color);
    }
  }, [color]);

  const handleChange = (event) => {
    saveChanges(event.target.value);
  };

  return (
    <>
      <div
        className={`article__subtitle ${
          showExplainer ? "element--highlighted" : ""
        }`}
      >
        {showExplainer && (
          <div
            className="element__explainer"
            dangerouslySetInnerHTML={{ __html: explainerText }}
          />
        )}
        <strong>Artikel kleur</strong>&nbsp;
        <select onChange={handleChange} value={selectedColor}>
          <option value="none">Kies kleur</option>
          {colors.map((color) => {
            return (
              <option value={color.value} key={`color-option-${color.value}`}>
                {color.title}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
}

export default ArticleDossier;
