import { useState, useEffect, useLayoutEffect, useRef } from "react";
import ArticleFlexContentToolbar from "./ArticleFlexContentToolbar";
import Button from "../Button";

const MIN_TEXTAREA_HEIGHT = 150;
const MIN_TEXTAREA_HEIGHT_QUOTE = 10;

function ArticleText(props) {
  const textareaRef = useRef(null);

  const [inputDirty, setInputDirty] = useState(false);
  const [text, setText] = useState("");

  const handleChange = (event) => {
    setInputDirty(true);
    setText(event.target.value);
  };

  useEffect(() => {
    setText(props.text !== null ? props.text : "");
  }, []);

  useLayoutEffect(() => {
    if (textareaRef === null || textareaRef.current === null) {
      return;
    }
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    // Set height
    if (props.classAddition === "quote") {
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,

        MIN_TEXTAREA_HEIGHT_QUOTE
      )}px`;
    } else {
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    }
  }, [text, props.mode]);

  switch (props.mode) {
    case "edit":
      return (
        <>
          <div
            className={`article__content_block   ${
              props.showExplainer ? "element--highlighted" : ""
            }`}
          >
            {props.showExplainer && (
              <div
                className="element__explainer"
                dangerouslySetInnerHTML={{ __html: props.explainerText }}
              />
            )}

            <textarea
              autoFocus
              className={`article__content_block--forminput article__content_block--${props.classAddition}`}
              onChange={handleChange}
              value={text}
              ref={textareaRef}
              style={{
                minHeight:
                  props.classAddition === "quote"
                    ? MIN_TEXTAREA_HEIGHT_QUOTE
                    : MIN_TEXTAREA_HEIGHT,
                resize: "none",
              }}
            />
            <div>
              <Button
                type="secondary"
                clickFunction={() => {
                  props.saveChanges(text);
                }}
              >
                Opslaan
              </Button>
              {/* {inputDirty && (
                <Button
                  onClick={() => {
                    setText(props.text);
                    setInputDirty(false);
                  }}
                >
                  Aanpassingen ongedaan maken
                </Button>
              )} */}
            </div>
          </div>
        </>
      );

    default:
      return (
        <div
          className={`article__content_block article__content_block--${props.classAddition}`}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {props.text}

          {props.editable && (
            <ArticleFlexContentToolbar
              removable={props.removable}
              removeClick={props.removeClick}
              editClick={props.editClick}
            />
          )}
        </div>
      );
  }
}
export default ArticleText;
