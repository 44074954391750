import { LoggedinContext } from "../context/loggedin";
import { useContext } from "react";
import { Route, Outlet } from "react-router-dom";

const PrivateRoute = ({ path, element }) => {
  const { loggedin, setLoggedin } = useContext(LoggedinContext);

  return loggedin ? <Outlet /> : <h1>403 Not Allowed</h1>;
};
export default PrivateRoute;
