import { useState, useEffect } from "react";
import { ReactComponent as LoadingIcon } from "../../icons/loading.svg";
import Directus from "../../directus/directus";

export default function IconLibrary(props) {
  const [loadingLibrary, setLoadingLibrary] = useState(true);
  const [icons, setIcons] = useState([]);
  useEffect(() => {
    async function fetchAPI() {
      const loadedIcons = await Directus.items("Icons").readByQuery({
        limit: -1,
      });

      setIcons(loadedIcons.data);
      setLoadingLibrary(false);
    }

    fetchAPI();
  }, []);

  const clickItem = (index) => {
    props.addIconToIllustration(icons[index].svgelements);
  };

  if (loadingLibrary) return <LoadingIcon />;

  return (
    <div className="icon_library rightside_toolbox">
      {loadingLibrary ? (
        <LoadingIcon />
      ) : (
        icons.map(function (icon, index) {
          return (
            <div
              className="icon_library__icon__container"
              key={`icon-${icon.name}`}
            >
              <svg
                width="200"
                height="200"
                viewBox="0 0 200 200"
                dangerouslySetInnerHTML={{ __html: icon.svgelements }}
                className="icon_library__icon"
                onClick={() => clickItem(index)}
              />
            </div>
          );
        })
      )}
    </div>
  );
}
