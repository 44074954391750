import React, { useState, useEffect, useContext } from "react";

import Illustration from "./Illustration";
import { LoggedinContext } from "../context/loggedin";

import { useNavigate, Link } from "react-router-dom";
import ArticleTitle from "./ArticleComponents/ArticleTitle";
import ArticleDossier from "./ArticleComponents/ArticleDossier";
import Button from "./Button";
import Directus from "../directus/directus";

const DOMPurify = require("dompurify")(window);

function ArticleList() {
  const navigate = useNavigate();
  const { loggedin, setLoggedin } = useContext(LoggedinContext);

  const [articles, setArticles] = useState([]);
  useEffect(() => {
    async function fetchAPI() {
      const loadedArticles = await Directus.items("Articles").readByQuery({
        limit: -1,
        sort: ["-id"],
        fields: ["id", "title", "illustration", "dossier.title", "color"],
        filter: {
          status: "published",
        },
      });

      setArticles(loadedArticles.data);
    }
    fetchAPI();
  }, []);

  const createNewArticle = async () => {
    const newIllustration = await Directus.items("Illustrations").createOne();

    const newArticle = await Directus.items("Articles").createOne({
      illustration: newIllustration.id,
    });

    navigate(`/article/update/${newArticle.id}`);
    return;
  };

  return (
    <>
      Inzicht is een uitgave van het domein Economie van Gemeente Heerlen.
      Inzicht communiceert de voortgang en resultaten van het beleid, de data en
      uitvoering om meer inzicht te krijgen in de onderlinge verbanden om
      hiermee raad en burger te kunnen informeren. en resultaten van het beleid,
      de data en uitvoering binnen het domein economie van de gemeente Heerlen.
      Inzicht stimuleert de interne communicatie en teamwork om meer inzicht te
      krijgen in de resultaten en vaerbanden tussen data, beleid en uitvoering
      om hiermee raad en burger te kunnen informeren.
      <section className="articlelist">
        <div className="container">
          {/* <article className="articlelist__article articlelist__article--1 articlelist__article--yellow">
            <h1 className="article__title">
              <span className="article__subtitle">
                <strong>Dossier</strong> Meer mensen aan het werk
              </span>
              Weg met ‘dark’-side Heerlen Centrum
            </h1>
            <div className="article__image">
              <ExampleIllustration />
            </div>
            <div className="article__footer">09/01/2022</div>
          </article>
          <article className="articlelist__article articlelist__article--2 articlelist__article--blue">
            <h1 className="article__title">
              <span className="article__subtitle">
                <strong>Dossier</strong> Krimp als kans
              </span>
              Minder faillissementen en tóch meer lege panden
            </h1>
            <div className="article__image">
              <Illustration />
            </div>
            <div className="article__footer">09/01/2022</div>
          </article>
          <article className="articlelist__article articlelist__article--3 articlelist__article--lightblue">
            <h1 className="article__title">
              <span className="article__subtitle">
                <strong>Dossier</strong> Meer mensen aan het werk
              </span>
              Weg met ‘dark’-side Heerlen Centrum
            </h1>
            <div className="article__image">
              <Illustration />
            </div>
            <div className="article__footer">09/01/2022</div>
          </article>
          <article className="articlelist__article articlelist__article--4 articlelist__article--pink">
            <h1 className="article__title">
              <span className="article__subtitle">
                <strong>Dossier</strong> Meer mensen aan het werk
              </span>
              Weg met ‘dark’-side Heerlen Centrum
            </h1>
            <div className="article__image">
              <Illustration />
            </div>
            <div className="article__footer">09/01/2022</div>
          </article> */}
          {articles.map((article, index) => {
            return (
              <Link
                to={`/article/${article.id}`}
                className={`articlelist__article articlelist__article--${
                  index + 1
                } articlelist__article--${article.color}`}
                key={`article-${index}`}
              >
                <ArticleDossier dossierTitle={article.dossier.title} />
                <ArticleTitle text={article.title} />

                <div className="article__image">
                  {/* <ExampleIllustration illustrationID={article.illustration} /> */}
                  <Illustration illustrationID={article.illustration} />
                </div>
                {/* <div className="article__footer">09/01/2022</div> */}
              </Link>
            );
          })}
        </div>
      </section>
      {loggedin && (
        <>
          <Button
            clickFunction={() => {
              createNewArticle();
            }}
          >
            Nieuw artikel maken
          </Button>
          <Button
            clickFunction={() => {
              navigate(`/login`);
            }}
          >
            Uitloggen
          </Button>
        </>
      )}
    </>
  );
}

export default ArticleList;
