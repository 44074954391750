import { useEffect, useLayoutEffect, useState, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import ArticleTitle from "./ArticleComponents/ArticleTitle";
import ArticleDossier from "./ArticleComponents/ArticleDossier";
import ArticleFlexContent from "./ArticleComponents/ArticleFlexContent";
import ArticleText from "./ArticleComponents/ArticleText";
import ArticleIllustration from "./ArticleComponents/ArticleIllustration";
import ArticleFlexContentToolbar from "./ArticleComponents/ArticleFlexContentToolbar";
import ArticleColorPicker from "./ArticleComponents/ArticleColorPicker";
import { LoggedinContext } from "../context/loggedin";

import Button from "./Button";
import Modal from "./Modal";
import Illustration from "./Illustration";
import Directus from "../directus/directus";

const DOMPurify = require("dompurify")(window);

const STEP_1_INTRODUCTION = 0;
const STEP_2_FLEXCONTENT = 1;
const STEP_2B_FLEXCONTENT_CONTINUE = 2;
const STEP_3_TITLE = 3;
const STEP_4_DOSSIER = 4;
const STEP_5_ILLUSTRATION = 5;
const STEP_6_COLOR = 6;
const STEP_7_FINISHED = 7;

function Article({ editable }) {
  const { loggedin, setLoggedin } = useContext(LoggedinContext);

  const [article, setArticle] = useState({ loading: true });
  const [firstRender, setFirstRender] = useState(true);
  const [editting, setEditting] = useState(-1);
  const [illustrationReRenderTimestamp, setIllustrationReRenderTimestamp] =
    useState(0);

  let { id: articleID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAPI() {
      const loadedArticle = await Directus.items("Articles").readOne(
        articleID,
        {
          fields: ["*", "dossier.title", "dossier.id"],
        }
      );
      if (loadedArticle.write_status === null) {
        loadedArticle.write_status = STEP_1_INTRODUCTION;
      }
      setEditingBasedOnWritingStatus(loadedArticle.write_status);
      loadedArticle.loading = false;
      setArticle(loadedArticle);
    }
    fetchAPI();
  }, []);

  useLayoutEffect(() => {
    if (article.loading) {
      return;
    }
    if (firstRender) {
      setFirstRender(false);
    } else {
      saveArticle();
    }
  }, [article]);

  const saveChanges = (property, value) => {
    let write_status = article.write_status;
    switch (property) {
      case "introduction":
        if (write_status < STEP_2_FLEXCONTENT) {
          write_status = STEP_2_FLEXCONTENT;
          setEditingBasedOnWritingStatus(write_status);
        } else {
          setEditting(-1);
        }
        break;
      case "content":
        if (write_status < STEP_2B_FLEXCONTENT_CONTINUE) {
          write_status = STEP_2B_FLEXCONTENT_CONTINUE;
          setEditingBasedOnWritingStatus(write_status);
        } else {
          if (write_status > STEP_2B_FLEXCONTENT_CONTINUE) {
            setEditingBasedOnWritingStatus(write_status);
          } else {
            setEditting("flexcontent.-1");
          }
        }
        break;
      case "title":
        if (write_status < STEP_4_DOSSIER) {
          write_status = STEP_4_DOSSIER;
          setEditingBasedOnWritingStatus(write_status);
        } else {
          setEditting(-1);
        }
        break;
      case "dossier":
        if (write_status < STEP_5_ILLUSTRATION) {
          write_status = STEP_5_ILLUSTRATION;
          setEditingBasedOnWritingStatus(write_status);
        } else {
          setEditting(-1);
        }
        break;
      case "color":
        if (write_status < STEP_7_FINISHED) {
          write_status = STEP_7_FINISHED;
          setEditingBasedOnWritingStatus(write_status);
        } else {
          setEditting(-1);
        }
        break;
      default:
        setEditting(-1);
    }

    setArticle({ ...article, [property]: value, write_status });
  };

  const saveArticle = async () => {
    try {
      let toBeSavedArticle = { ...article };

      if (article.dossier !== null && undefined !== article.dossier.id) {
        toBeSavedArticle.dossier = article.dossier.id;
      } else {
        delete toBeSavedArticle.dossier;
      }
      if (
        article.illustration !== null &&
        undefined !== article.illustration.id
      ) {
        toBeSavedArticle.illustration = article.illustration.id;
      } else {
        delete toBeSavedArticle.illustration;
      }

      if (article.write_status === STEP_7_FINISHED) {
        article.status = "published";
      }

      await Directus.items("Articles").updateOne(articleID, article);
    } catch (e) {
      console.log("D'oh! We couldn't save that.");
    }
  };

  const gotoNextStep = () => {
    const newStatus = article.write_status + 1;
    setArticle({ ...article, write_status: newStatus });
    setEditingBasedOnWritingStatus(newStatus);
  };

  const setEditingBasedOnWritingStatus = (step) => {
    switch (step) {
      case STEP_1_INTRODUCTION:
        setEditting("introduction");
        break;
      case STEP_2_FLEXCONTENT:
        setEditting("flexcontent.-1");
        break;
      case STEP_2B_FLEXCONTENT_CONTINUE:
        setEditting("flexcontent.-1");
        break;
      case STEP_3_TITLE:
        setEditting("title");
        break;
      case STEP_4_DOSSIER:
        setEditting("dossier");
        break;
      case STEP_5_ILLUSTRATION:
        setEditting("illustration");
        break;
      case STEP_6_COLOR:
        setEditting("color");
        break;
      case STEP_7_FINISHED:
        setEditting(-1);
        break;

      default:
    }
  };

  const editClick = (element) => {
    setEditting(element);
  };

  const removeArticle = async () => {
    if (
      window.confirm("Weet je zeker dat je het hele artikel wilt verwijderen?")
    ) {
      await Directus.items("Articles").deleteOne(articleID);
      debugger;
      navigate(`/`);
    }
  };
  const editArticle = async () => {
    navigate(`/article/update/${articleID}`);
  };

  if (article.loading) {
    return;
  }
  return (
    <>
      <article
        className={`article article--centered article--${
          article.color !== null &&
          article.color !== undefined &&
          article.color !== ""
            ? article.color
            : ""
        }`}
      >
        {article.write_status > STEP_2B_FLEXCONTENT_CONTINUE && (
          <div className="article__cover">
            <div className="article__cover_content">
              {article.write_status >= STEP_4_DOSSIER && (
                <ArticleDossier
                  mode={editting === "dossier" ? "edit" : "presentation"}
                  saveChanges={(dossierObject) => {
                    saveChanges("dossier", dossierObject);
                  }}
                  dossierId={article.dossier ? article.dossier.id : -1}
                  dossierTitle={article.dossier ? article.dossier.title : ""}
                  editClick={editClick}
                  showExplainer={article.write_status === STEP_4_DOSSIER}
                  explainerText="<strong>Stap 4 - dossier keuze</strong><br />Onder welk dossier valt dit artikel?"
                  editable={editable}
                />
              )}
              <ArticleTitle
                text={article.title}
                mode={editting === "title" ? "edit" : "presentation"}
                editting={editting}
                setEditting={setEditting}
                saveChanges={(text) => {
                  saveChanges("title", text);
                }}
                editClick={editClick}
                showExplainer={article.write_status === STEP_3_TITLE}
                explainerText="<strong>Stap 3 - Type een pakkende titel</strong><br />Gebruik maximaal 7 woorden om een pakkende titel voor dit artikel te schrijven."
                editable={editable}
              />
              {article.write_status === STEP_5_ILLUSTRATION && (
                <ArticleIllustration
                  illustrationId={article.illustration}
                  showExplainer={article.write_status === STEP_5_ILLUSTRATION}
                  explainerText="<strong>Stap 5 - Creëer een illustratie bij dit artikel."
                  gotoNextStep={gotoNextStep}
                  setIllustrationReRenderTimestamp={
                    setIllustrationReRenderTimestamp
                  }
                />
              )}

              {article.write_status >= STEP_6_COLOR && (
                <div className="article__illustration">
                  <Illustration
                    editMode={false}
                    illustrationID={article.illustration}
                    illustrationReRenderTimestamp={
                      illustrationReRenderTimestamp
                    }
                    editable={editable}
                  />
                  {editable && (
                    <ArticleFlexContentToolbar
                      removable={false}
                      editClick={() => {
                        setEditting("illustration");
                      }}
                    />
                  )}
                  {!editable && <Link to={`/article/update/${article.id}`} />}
                </div>
              )}
              {article.write_status >= STEP_6_COLOR &&
                editting === "illustration" && (
                  <Modal
                    hideModal={() => {
                      setEditting(-1);
                    }}
                  >
                    <Illustration
                      editMode={true}
                      hideModal={() => {
                        setEditting(-1);
                      }}
                      illustrationID={article.illustration}
                      setIllustrationReRenderTimestamp={
                        setIllustrationReRenderTimestamp
                      }
                    />
                  </Modal>
                )}

              {article.write_status >= STEP_6_COLOR && editable && (
                <ArticleColorPicker
                  color={article.color}
                  showExplainer={article.write_status === STEP_6_COLOR}
                  explainerText="<strong>Stap 5 - Kies een achtergrond kleur."
                  saveChanges={(color) => {
                    saveChanges("color", color);
                  }}
                />
              )}
            </div>
          </div>
        )}
        <div className="article__content">
          <ArticleText
            mode={editting === "introduction" ? "edit" : "presentation"}
            removable={false}
            classAddition="introduction"
            saveChanges={(text) => {
              saveChanges("introduction", text);
            }}
            text={article.introduction}
            editting={editting}
            setEditting={setEditting}
            editClick={() => {
              editClick("introduction");
            }}
            showExplainer={article.write_status === STEP_1_INTRODUCTION}
            explainerText="<strong>Stap 1 - schrijf een introductie</strong><br />Probeer zo compact mogelijk een samenvatting te geven van waar dit artikel over zal gaan."
            editable={editable}
          />
          {article.write_status >= STEP_2_FLEXCONTENT && (
            <ArticleFlexContent
              content={article.content}
              saveChanges={(content) => {
                saveChanges("content", content);
              }}
              write_status={article.write_status}
              gotoNextStep={gotoNextStep}
              editting={editting}
              setEditting={setEditting}
              editClick={editClick}
              showExplainer={article.write_status === STEP_2_FLEXCONTENT}
              explainerText="<strong>Stap 2 - artikel opbouw</strong><br />Schrijf in enkele paragrafen het artikel. Vul deze aan met afbeeldingen en quotes."
              editable={editable}
            />
          )}
        </div>
      </article>
      {!editable && loggedin && (
        <Button
          clickFunction={() => {
            editArticle();
          }}
        >
          Artikel aanpassen
        </Button>
      )}
      {editable && (
        <Button
          clickFunction={() => {
            removeArticle();
          }}
        >
          Artikel verwijderen
        </Button>
      )}
    </>
  );
}

export default Article;
