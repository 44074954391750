import { useContext } from "react";
import { LoggedinContext } from "../context/loggedin";
import Button from "./Button";
import { Link } from "react-router-dom";

function Login() {
  const { loggedin, setLoggedin } = useContext(LoggedinContext);

  const toggleLogin = () => {
    setLoggedin(!loggedin);
  };

  if (loggedin) {
    return (
      <div>
        Je bent ingelogd. <Link to="/">Klik hier</Link> om naar de homepage te
        gaan.
        <br />
        <br />
        <Button clickFunction={() => toggleLogin()}>Uitloggen</Button>
      </div>
    );
  }
  return (
    <div>
      <input type="text" placeholder="username" />
      <br />
      <input type="password" placeholder="password" />
      <br />
      <br />
      <Button clickFunction={() => toggleLogin()}>Login</Button>
    </div>
  );
}
export default Login;
