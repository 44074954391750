import { ReactComponent as Logo } from "../svgs/Logo-heerlen.svg";

function Footer() {
  return (
    <footer>
      <div className="pagewrapper footer__container">
        <div class="footer__column">
          <Logo />
        </div>
        <div class="footer__column">
          <strong class="footer__column_title">Ontwikkeld door:</strong>
          Domein Economie
          <br />
          Gemeente Heerlen
        </div>
        <div class="footer__column">
          <strong class="footer__column_title">In samenwerking met:</strong>
          Studio Hyphen en <br />
          Ontwerpburo Homo Ludens
        </div>
        <div class="footer__column">
          <strong class="footer__column_title">Contact:</strong>
          <a href="mailto=R.Schoffelen@heerlen.nl">r.schoffelen@heerlen.nl</a>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
