function Button({
  clickFunction,
  children,
  type = "secondary",
  disabled = false,
}) {
  return (
    <div
      className={`button button--${type} ${
        disabled ? "button--disabled" : ""
      } `}
      onClick={() => {
        if (!disabled) {
          clickFunction();
        }
      }}
    >
      {children}
    </div>
  );
}
export default Button;
