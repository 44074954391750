import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import { ReactComponent as RemoveIcon } from "../../icons/remove.svg";

const FlexcontentToolBar = ({
  removable,
  editable = true,
  editClick,
  removeClick,
}) => {
  return (
    <div className="article__content_block__actions">
      {editable && <EditIcon onClick={editClick} />}
      {removable && <RemoveIcon onClick={removeClick} />}
    </div>
  );
};

export default FlexcontentToolBar;
