import Button from "../Button";
export default function IllustrationToolbox({
  updateActiveIconColor,
  updateActiveRotation,
  updateActiveScale,
  colors,
  selectedColor,
  close,
  deleteIcon,
  currentRotation,
  currentScale,
}) {
  return (
    <div className=" rightside_toolbox">
      <h1>Icoon aanpassen</h1>
      <h3>Kleur</h3>
      <div className="illustration__toolbox">
        {colors.map((color) => (
          <div
            key={`color-selector-${color}`}
            className={`illustration__toolbox__color ${
              color === selectedColor
                ? "illustration__toolbox__color--selected"
                : ""
            }`}
            style={{
              background: color,
            }}
            onClick={() => {
              updateActiveIconColor(color);
            }}
          />
        ))}
      </div>
      <div>
        <h3>Draaien </h3>
        <input
          type="range"
          min="-360"
          max="360"
          onChange={(e) => {
            updateActiveRotation(parseInt(e.target.value));
          }}
          value={currentRotation}
        />
        <br />

        <h3>Schalen</h3>
        <input
          type="range"
          min="1"
          max="20"
          onChange={(e) => {
            updateActiveScale(parseInt(e.target.value) / 10);
          }}
          value={currentScale * 10}
        />
      </div>
      <br />
      <Button clickFunction={close}>Icoon editor sluiten</Button>
      <Button clickFunction={deleteIcon}>Icoon verwijderen</Button>
    </div>
  );
}
