import { ReactComponent as Logo } from "../svgs/Logo.svg";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
const MainLayout = ({ children }) => {
  return (
    <>
      <header className="header">
        <div className="pagewrapper">
          <Link to="/">
            <Logo className="header__logo" />
          </Link>

          <div className="header__edtion_information">
            <span>Editie #01</span> <span>maart 2023</span>
          </div>
        </div>
      </header>
      <main>
        <div className="pagewrapper">{children}</div>
      </main>
      <Footer />
    </>
  );
};
export default MainLayout;
