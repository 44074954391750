import { useEffect, useState } from "react";
import ArticleText from "./ArticleText";
import ArticleImage from "./ArticleImage";
import Button from "../Button";

const flexcontentTypes = [
  { name: "text", label: "tekst" },
  { name: "image", label: "afbeelding" },
  { name: "quote", label: "quote" },
];
function ArticleFlexContent(props) {
  const [flexcontent, setFlexContent] = useState([]);

  useEffect(() => {
    if (!Array.isArray(props.content)) {
      setFlexContent([]);
    } else {
      setFlexContent(props.content);
    }
    if (props.editting === "flexcontent") {
      props.setEditting("flexcontent.-1");
    }
  }, []);

  const showToolbox = () => {
    if (undefined === props.editting || props.editable === false) {
      return false;
    }
    if (props.editting === -1) {
      return true;
    }
    return (
      props.editting.substr(0, 11) !== "flexcontent" ||
      props.editting === "flexcontent.-1"
    );
  };
  const addContentBlock = (type) => {
    const contentCount = flexcontent.length;
    const newFlexContent = [...flexcontent, { type, content: "" }];
    setFlexContent(newFlexContent);
    props.setEditting(`flexcontent.${contentCount}`);
  };

  const removeContentBlock = (index) => {
    if (window.confirm("Weet je zeker dat je dit wilt verwijderen?")) {
      const newFlexContent = [...flexcontent];
      newFlexContent.splice(index, 1);

      setFlexContent(newFlexContent);
      props.setEditting("flexcontent.-1");
      props.saveChanges(newFlexContent);
    }
  };

  const saveChanges = (index, content) => {
    const newFlexContent = [...flexcontent];
    newFlexContent[index] = { ...newFlexContent[index], content };

    switch (flexcontent[index].type) {
      case "quote":
      case "text":
        if (content === "") {
          newFlexContent.pop();
        }
        setFlexContent(newFlexContent);
        break;

      case "image":
        setFlexContent(newFlexContent);
        break;
      default:
        console.log("Not yet specified!");
        break;
    }
    props.setEditting("flexcontent.-1");
    props.saveChanges(newFlexContent);
  };

  return (
    <div
      className={`article__flexcontent_container ${
        props.showExplainer ? "element--highlighted" : ""
      }`}
    >
      {props.showExplainer && (
        <div
          className="element__explainer"
          dangerouslySetInnerHTML={{ __html: props.explainerText }}
        />
      )}
      {flexcontent.length > 0 &&
        flexcontent.map((contentblock, index) => {
          switch (contentblock.type) {
            case "text":
              return (
                <ArticleText
                  key={`flexcontent.${index}`}
                  classAddition="text"
                  text={
                    undefined !== contentblock.content
                      ? contentblock.content
                      : ""
                  }
                  mode={
                    `flexcontent.${index}` === props.editting
                      ? "edit"
                      : "presentation"
                  }
                  removable={true}
                  saveChanges={(text) => {
                    saveChanges(index, text);
                  }}
                  editClick={() => {
                    props.editClick(`flexcontent.${index}`);
                  }}
                  editable={props.editable}
                  removeClick={() => {
                    removeContentBlock(index);
                  }}
                />
              );
            case "image":
              return (
                <ArticleImage
                  key={`flexcontent.${index}`}
                  image={
                    undefined !== contentblock.content
                      ? contentblock.content
                      : ""
                  }
                  mode={
                    `flexcontent.${index}` === props.editting
                      ? "edit"
                      : "presentation"
                  }
                  saveChanges={(imageId) => {
                    saveChanges(index, imageId);
                  }}
                  editable={props.editable}
                  removeClick={() => {
                    removeContentBlock(index);
                  }}
                />
              );

            case "quote":
              return (
                <ArticleText
                  key={`flexcontent.${index}`}
                  mode={
                    `flexcontent.${index}` === props.editting
                      ? "edit"
                      : "presentation"
                  }
                  classAddition="quote"
                  hasQuotes={true}
                  removable={true}
                  text={
                    undefined !== contentblock.content
                      ? contentblock.content
                      : ""
                  }
                  saveChanges={(text) => {
                    saveChanges(index, text);
                  }}
                  editClick={() => {
                    props.editClick(`flexcontent.${index}`);
                  }}
                  removeClick={() => {
                    removeContentBlock(index);
                  }}
                  editable={props.editable}
                />
              );
            default:
              return "[onbekend content block]";
          }
        })}
      {showToolbox() && (
        <div className="article_toolbox">
          {flexcontentTypes.map((type, index) => {
            return (
              <Button
                key={`toolbox-${index}`}
                clickFunction={() => {
                  addContentBlock(type.name);
                }}
                type="secondary"
              >
                + {type.label} toevoegen
              </Button>
            );
          })}
        </div>
      )}

      {props.editting === "flexcontent.-1" &&
        flexcontent.length > 0 &&
        props.write_status === 2 && (
          <>
            <br />
            <br />
            <br />
            <br />

            <div className="element__explainer element--highlighted">
              <strong>Stap 2 - artikel opbouw</strong>
              <p>Klaar met de opbouw van het artikel?</p>
              <br />
              <br />
              <Button
                clickFunction={() => {
                  props.gotoNextStep();
                }}
              >
                Naar de volgende stap
              </Button>
            </div>
          </>
        )}
    </div>
  );
}

export default ArticleFlexContent;
