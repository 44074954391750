import { useState, useEffect } from "react";
import Directus from "../../directus/directus";
import ArticleFlexContentToolbar from "./ArticleFlexContentToolbar";

function ArticleDossier({
  dossierId,
  dossierTitle,
  saveChanges,
  mode,
  editClick,
  showExplainer,
  editable,
}) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    async function fetchAPI() {
      const loadedDossiers = await Directus.items("Dossiers").readByQuery({
        sort: ["id"],
      });

      setOptions(loadedDossiers.data);
    }
    if (mode === "edit") {
      fetchAPI();
    }
  }, []);
  const handleChange = (event) => {
    const id = parseInt(event.target.value);
    if (id !== "-1" && id !== "none") {
      saveChanges(options.find((option) => option.id === id));
    }
  };

  switch (mode) {
    case "edit":
      return (
        <>
          <div
            className={`article__subtitle ${
              showExplainer ? "element--highlighted" : ""
            }`}
          >
            <strong>Dossier</strong>&nbsp;
            <select onChange={handleChange} value={dossierId}>
              <option value="none">Kies dossier</option>
              {options.map((option) => {
                return (
                  <option value={option.id} key={`dossier-option-${option.id}`}>
                    {option.title}
                  </option>
                );
              })}
            </select>
          </div>
        </>
      );

    default:
      return (
        <div className="article__subtitle">
          <strong>Dossier</strong>
          {dossierTitle}
          {editable && (
            <ArticleFlexContentToolbar
              removable={false}
              editClick={() => {
                editClick("dossier");
              }}
            />
          )}
        </div>
      );
  }
}

export default ArticleDossier;
