import { useState, useRef, useLayoutEffect, useEffect } from "react";
import Draggable from "gsap/Draggable";
import InertiaPlugin from "gsap/InertiaPlugin";
import IconLibrary from "./IllustrationComponents/IconLibrary";
import IllustrationToolbox from "./IllustrationComponents/IllustrationToolbox";
import gsap from "gsap";
import Directus from "../directus/directus";
import Button from "./Button";

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

const Illustration = ({
  editMode = false,
  illustrationID,
  hideModal,
  showExplainer,
  gotoNextStep,
  illustrationReRenderTimestamp,
  setIllustrationReRenderTimestamp,
}) => {
  const colors = [
    "#3C51D8",
    "#D6303B",
    "#F7473C",
    "#E7CDD5",
    "#A3B9BD",
    "#0A6C42",
    "#1A9C83",
    "#C4C4EC",
    "#D4C671",
    "#AAA2D7",
  ];

  const svgRef = useRef(null);
  const iconRefs = useRef([]);
  const [icons, setIcons] = useState([]);
  const style = { mixBlendMode: "multiply" };
  const [activeIcon, setActiveIcon] = useState(null);
  const [saveableChanges, setSaveableChanges] = useState(false);

  useEffect(() => {
    async function fetchAPI() {
      const loadedIllustration = await Directus.items("Illustrations").readOne(
        illustrationID
      );

      setIcons(
        loadedIllustration.illustration !== null
          ? loadedIllustration.illustration
          : []
      );
    }
    fetchAPI();
  }, [illustrationReRenderTimestamp]);

  useLayoutEffect(() => {
    iconRefs.current.forEach((iconElement, index) => {
      console.log("icons", icons);
      let q = gsap.utils.selector(svgRef.current);
      if (icons[index] !== undefined) {
        gsap.set(q(`[data-index='${index}']`), {
          x: icons[index].transformX || 0,
          y: icons[index].transformY || 0,
          rotate: icons[index].rotation || 0,
          scale: icons[index].scale || 1,
          transformOrigin: "center",
        });
      }
    });
    if (editMode) {
      iconRefs.current.forEach((iconElement) => {
        makeElementDraggable(iconElement);
      });
    }
  }, [icons]);

  const makeElementDraggable = (element) => {
    Draggable.create(element, {
      type: "x,y",
      bounds: svgRef.current,
      overshootTolerance: 0,
      inertia: true,
      edgeResistance: 0.5,
      onDragStart: function () {
        setActiveIcon(parseInt(element.getAttribute("data-index")));
        setSaveableChanges(true);
      },
      onDragEnd: function () {
        const elementIndex = element.getAttribute("data-index");
        let newIcons = [...icons];
        let updatedIcon = { ...icons[elementIndex] };
        updatedIcon.transformX = this.x;
        updatedIcon.transformY = this.y;
        newIcons[elementIndex] = updatedIcon;
        setIcons(newIcons);
        setSaveableChanges(true);
      },
    });
  };

  const randomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const addIconToIllustration = (iconelements) => {
    setIcons([
      ...icons,
      {
        data: iconelements,
        color: randomColor(),
        transformX: 0,
        transformY: 0,
      },
    ]);

    setActiveIcon(icons.length);
    setSaveableChanges(true);
  };

  const emptyIllustration = () => {
    setIcons([]);
  };

  const updateActiveIconColor = (newColor) => {
    setIcons(
      icons.map((icon, index) => {
        if (index !== activeIcon) {
          return icon;
        } else {
          return { ...icon, color: newColor };
        }
      })
    );
    setSaveableChanges(true);
  };

  const updateActiveRotation = (rotation) => {
    gsap.set(`[data-index='${activeIcon}']`, {
      rotation,
      transformOrigin: "center",
    });

    setIcons(
      icons.map((icon, index) => {
        if (index !== activeIcon) {
          return icon;
        } else {
          return { ...icon, rotation: rotation };
        }
      })
    );
    setSaveableChanges(true);
  };

  const updateActiveScale = (scale) => {
    gsap.set(`[data-index='${activeIcon}']`, {
      scale,
      transformOrigin: "center",
    });

    setIcons(
      icons.map((icon, index) => {
        if (index !== activeIcon) {
          return icon;
        } else {
          return { ...icon, scale };
        }
      })
    );
    setSaveableChanges(true);
  };

  const deleteIcon = () => {
    let newIcons = [...icons];
    newIcons.splice(activeIcon, 1);
    setIcons(newIcons);
    setActiveIcon(null);
  };

  const saveIllustration = async () => {
    setSaveableChanges(false);
    await Directus.items("Illustrations").updateOne(illustrationID, {
      illustration: icons,
    });
    setIllustrationReRenderTimestamp(Date.now());
  };

  return (
    <>
      <div className="illustration_container">
        <div id="svg" className="illustration">
          <svg
            width="200"
            height="200"
            viewBox="0 0 200 200"
            ref={svgRef}
            onClick={() => {
              setActiveIcon(null);
            }}
          >
            <defs>
              <mask id="text-mask-3" x="0" y="0" width="200" height="200">
                <image
                  width="200"
                  height="200"
                  y="0"
                  x="0"
                  xlinkHref="https://image.shutterstock.com/image-vector/distress-grainy-dust-overlay-grunge-260nw-391295581.jpg"
                />
              </mask>
              <mask id="text-mask-4" x="0" y="0" width="200" height="200">
                <image
                  width="200"
                  height="200"
                  y="0"
                  x="0"
                  xlinkHref="https://st10.cannypic.com/thumbs/43/431489_632_canny_pic.jpg"
                />
              </mask>
              <mask id="texturemask1" x="0" y="0" width="200" height="200">
                <image
                  width="100"
                  height="100"
                  y="0"
                  x="0"
                  xlinkHref="/img/textures/riso-texture-01.png"
                />
                <image
                  width="100"
                  height="100"
                  y="100"
                  x="0"
                  xlinkHref="/img/textures/riso-texture-01.png"
                />
                <image
                  width="100"
                  height="100"
                  y="0"
                  x="100"
                  xlinkHref="/img/textures/riso-texture-01.png"
                />
                <image
                  width="100"
                  height="100"
                  y="100"
                  x="100"
                  xlinkHref="/img/textures/riso-texture-01.png"
                />
              </mask>
            </defs>

            {icons !== null &&
              icons.length > 0 &&
              icons.map((icon, index) => {
                switch (icon.type) {
                  case "circle":
                    return (
                      <circle
                        style={style}
                        r={icon.r}
                        cy={icon.cx}
                        cx={icon.cy}
                        mask={`url(${icon.mask})`}
                        fill={icon.fill}
                        ref={(el) => (iconRefs.current[index] = el)}
                        key={`icon-${index}`}
                        transform={`translate(${icon.transformX | 0},${
                          icon.transformY | 0
                        })`}
                        data-index={index}
                      />
                    );
                  case "rect":
                    return (
                      <rect
                        style={style}
                        width={icon.width}
                        height={icon.height}
                        y={icon.y}
                        x={icon.x}
                        mask={`url(${icon.mask})`}
                        fill={icon.fill}
                        key={`icon-${index}`}
                        ref={(el) => (iconRefs.current[index] = el)}
                        transform={`translate(${icon.transformX},${icon.transformY})`}
                        data-index={index}
                      />
                    );

                  default:
                    return (
                      <g
                        style={style}
                        width={icon.width}
                        height={icon.height}
                        y={icon.y}
                        x={icon.x}
                        mask={`url(#texturemask1)`}
                        fill={icon.color}
                        key={`icon-${index}`}
                        ref={(el) => (iconRefs.current[index] = el)}
                        // transform={`translate(${icon.transformX},${
                        //   icon.transformY
                        // }) rotate(${icon.rotation | 0} 100 100)`}
                        data-index={index}
                        dangerouslySetInnerHTML={{ __html: icon.data }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveIcon(index);
                        }}
                        className={
                          index === activeIcon
                            ? "illustration__path--selected"
                            : "'"
                        }
                      />
                    );
                }
              })}
          </svg>
        </div>
      </div>

      {editMode && (
        <>
          {activeIcon !== null ? (
            <>
              <IllustrationToolbox
                updateActiveIconColor={updateActiveIconColor}
                updateActiveRotation={updateActiveRotation}
                updateActiveScale={updateActiveScale}
                colors={colors}
                selectedColor={icons[activeIcon].color}
                currentRotation={icons[activeIcon].rotation || 0}
                currentScale={icons[activeIcon].scale || 1}
                close={() => {
                  setActiveIcon(null);
                }}
                deleteIcon={() => {
                  deleteIcon();
                }}
              />
            </>
          ) : (
            <IconLibrary addIconToIllustration={addIconToIllustration} />
          )}

          {activeIcon === null && (
            <div className="illustration__controls rightside_toolbox">
              <Button
                disabled={!saveableChanges}
                clickFunction={() => {
                  saveIllustration();

                  if (showExplainer) {
                    gotoNextStep();
                  }
                }}
              >
                Opslaan
              </Button>
              {/* <button
              clickFunction={() => {
                emptyIllustration();
              }}
            >
              Reset
            </button> */}
              <Button
                clickFunction={() => {
                  hideModal();
                }}
              >
                Editor afsluiten
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Illustration;
