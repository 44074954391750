import { useState, useEffect } from "react";
import ArticleFlexContentToolbar from "./ArticleFlexContentToolbar";

import directus from "../../directus/directus";

function ArticleImage(props) {
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imageId, setImageId] = useState(props.image);
  const handleFileInput = async (event) => {
    setUploadingImage(true);

    const file = event.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file, file.name);
    // const result = await directus_uploadImageToWincode(formdata, wincode.id);
    // const result = await directus.axios.post(`${directus.url}/files`, formdata);
    const result = await directus.files.createOne(formdata);

    setImageId(result.id);
    setUploadingImage(false);
    props.saveChanges(result.id);
  };
  return (
    <>
      {imageId == "" && !uploadingImage && (
        <input type="file" onChange={(e) => handleFileInput(e)} />
      )}
      {uploadingImage && <div>Uploading</div>}
      {imageId !== "" && (
        <div className="article__content_block article__content_block--image">
          <img
            src={`${directus._url}/assets/${imageId}/?width=1000&withoutEnlargement`}
          />
          {props.editable && (
            <ArticleFlexContentToolbar
              removable={true}
              editable={false}
              removeClick={props.removeClick}
            />
          )}
        </div>
      )}
    </>
  );
}
export default ArticleImage;
