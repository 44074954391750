import "./styles/main.scss";
import MainLayout from "./layouts/MainLayout";
import ArticleList from "./components/ArticleList";
import Article from "./components/Article";
import IconLibraryUpload from "./components/IconLibraryUpload";
import { LoggedinContextProvider, LoggedinContext } from "./context/loggedin";
import Login from "./components/Login";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <LoggedinContextProvider>
      <MainLayout>
        <Routes>
          <Route exact path="/" element={<ArticleList />} />
          <Route path="article">
            <Route path=":id" element={<Article editable={false} />} />
            <Route path="update" element={<PrivateRoute />}>
              <Route path=":id" element={<Article editable={true} />} />
            </Route>
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<PrivateRoute />}>
            <Route exact path="upload" element={<IconLibraryUpload />} />
          </Route>

          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
      </MainLayout>
    </LoggedinContextProvider>
  );
}

export default App;
