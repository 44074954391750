import { useState } from "react";

import Button from "../Button";
import Modal from "../Modal";
import Illustration from "../Illustration";
function ArticleIllustration(props) {
  const [editorIsOpen, setEditorIsOpen] = useState(false);
  const hideModal = () => {
    setEditorIsOpen(false);
  };
  return (
    <div
      className={`article__illustration ${
        props.showExplainer ? "element--highlighted" : ""
      }`}
    >
      {props.showExplainer && (
        <div
          className="element__explainer"
          dangerouslySetInnerHTML={{ __html: props.explainerText }}
        />
      )}
      {!editorIsOpen && (
        <Button
          clickFunction={() => {
            setEditorIsOpen(true);
          }}
        >
          Open de illustratie editor
        </Button>
      )}
      {editorIsOpen && (
        <Modal hideModal={hideModal}>
          <Illustration
            editMode={true}
            hideModal={hideModal}
            illustrationID={props.illustrationId}
            gotoNextStep={props.gotoNextStep}
            showExplainer={props.showExplainer}
            setIllustrationReRenderTimestamp={() => {
              props.setIllustrationReRenderTimestamp();
            }}
          />
        </Modal>
      )}
    </div>
  );
}
export default ArticleIllustration;
