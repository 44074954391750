import { useEffect } from "react";
import { useState, createContext } from "react";

export const LoggedinContext = createContext();
export const LoggedinContextProvider = ({ children }) => {
  const salt = "Th_e__s_a_lT";

  const [loggedin, setLoggedinToState] = useState(false);
  const setLoggedin = (value) => {
    setLoggedinToState(value);
    if (value) {
      const timestamp = new Date().getTime();
      const encodedTimestamp = btoa(timestamp + salt);
      localStorage.setItem("loggedin", encodedTimestamp);
      localStorage.setItem("loggedinDEcoded", timestamp);
    } else {
      localStorage.removeItem("loggedin");
    }
  };

  useEffect(() => {
    const localstorageStamp = localStorage.getItem("loggedin");
    if (localstorageStamp) {
      const decodedValue = atob(localstorageStamp);
      const decodedTimestamp = decodedValue.substring(
        0,
        decodedValue.length - salt.length
      );

      const loggedinTime =
        1000 * // 1 seconden
        60 * // minuten
        60 * // 1 uur
        24; // 1 dag

      if (new Date().getTime() - parseInt(decodedTimestamp) < loggedinTime) {
        setLoggedin(true);
      } else {
        localStorage.removeItem("loggedin");
      }
    }
  }, []);

  return (
    <LoggedinContext.Provider value={{ loggedin, setLoggedin }}>
      {children}
    </LoggedinContext.Provider>
  );
};
