import { useState } from "react";
import { debounce } from "lodash";
import { ReactComponent as Loading } from "../svgs/loading.svg";
import Directus from "../directus/directus";

export default function IconLibraryUpload(props) {
  var stringToDomParser = new DOMParser();
  var domToStringParser = new XMLSerializer();

  const [loading, setLoading] = useState(false);
  const [loadedIcons, setLoadedIcons] = useState([]);
  const [notification, setNotification] = useState("");
  const newlyLoadedIcons = [];
  const debouncedFileUpdater = debounce(() => {
    setLoadedIcons(newlyLoadedIcons);
    setLoading(false);
  }, 1000);

  const saveLoadedIcons = async () => {
    const result = await Directus.items("Icons").createMany(loadedIcons);
    setLoading(false);
    setLoadedIcons([]);
    setNotification("Icons opgeslagen.");
  };
  function readmultifiles(files) {
    setLoading(true);
    for (let i = 0; i < files.length; i++) {
      let fileReader = new FileReader();
      let file = files[i];
      fileReader.onloadend = (e) => {
        const content = e.currentTarget.result;
        const parsedElement = stringToDomParser.parseFromString(
          content,
          "image/svg+xml"
        );
        const errorNode = parsedElement.querySelector("parsererror");
        if (errorNode) {
          // not a correct SVG;
        } else {
          // correctly parsed SVG
          const svgChildNodes = parsedElement.querySelector("svg").childNodes;
          let textContent = "";
          svgChildNodes.forEach((node) => {
            if (undefined !== node.hasAttribute && node.hasAttribute("style")) {
              node.removeAttribute("style");
            }
            if (undefined !== node.hasAttribute && node.hasAttribute("id")) {
              node.removeAttribute("id");
            }

            let nodeText = domToStringParser.serializeToString(node);
            nodeText = nodeText.replace(
              'xmlns="http://www.w3.org/2000/svg"',
              ""
            );
            nodeText = nodeText.replace("  ", " ");
            textContent += nodeText;
          });

          newlyLoadedIcons.push({ name: file.name, svgelements: textContent });
          debouncedFileUpdater();
        }
      };
      fileReader.readAsText(file);
    }
  }

  return (
    <div className="imageupload">
      {notification !== "" && (
        <div className="imageupload__notification">{notification}</div>
      )}
      <h1>upload image</h1>

      <input
        type="file"
        accept=".svg"
        onChange={(e) => readmultifiles(e.target.files)}
        multiple="multiple"
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {loadedIcons.map(function (icon, i) {
            return (
              <svg
                width="200"
                height="200"
                viewBox="0 0 200 200"
                dangerouslySetInnerHTML={{ __html: icon.svgelements }}
                key={`icon-${icon.name}`}
                className="imageupload__preview"
              />
            );
          })}
          {loadedIcons.length > 0 && (
            <button onClick={() => saveLoadedIcons()}>
              Toevoegen aan library
            </button>
          )}
        </>
      )}
    </div>
  );
}
