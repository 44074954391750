import { useState, useEffect, useLayoutEffect } from "react";
import ArticleFlexContentToolbar from "./ArticleFlexContentToolbar";
import Button from "../Button";

function ArticleTitle(props) {
  const [inputDirty, setInputDirty] = useState(false);
  const [wordCount, setWordcount] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState(false);

  const titleNotSaved =
    props.text === "" || props.text === null || props.text === undefined;
  const handleChange = (event) => {
    setInputDirty(true);
    setText(event.target.value.replace("  ", " "));
  };

  const countWords = () => {
    const count = text.split(" ").length;
    setWordcount(count);

    if (count > 7) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const boldAsterisks = (text) => {
    var boldText = text.replace(/\*(.*?)\*/g, "<strong>$1</strong>");
    return boldText;
  };

  useLayoutEffect(() => {
    countWords();
  }, [text]);

  useEffect(() => {
    setText(props.text !== undefined && props.text !== null ? props.text : "");
  }, []);

  switch (props.mode) {
    case "edit":
      return (
        <div className={`${props.showExplainer ? "element--highlighted" : ""}`}>
          {props.showExplainer && (
            <div
              class="element__explainer"
              dangerouslySetInnerHTML={{ __html: props.explainerText }}
            />
          )}
          <textarea
            onChange={handleChange}
            type="text"
            value={text}
            className="article__title"
          />
          {error && (
            <div style={{ color: "red" }}>
              Te veel woorden ({wordCount} / 7)
            </div>
          )}
          <Button
            clickFunction={() => props.saveChanges(text)}
            disabled={text === "" || error}
          >
            Opslaan
          </Button>
        </div>
      );

    default:
      return (
        <h1 className="article__title">
          <div dangerouslySetInnerHTML={{ __html: boldAsterisks(text) }} />

          {props.editable && (
            <ArticleFlexContentToolbar
              removable={false}
              editClick={() => {
                props.editClick("title");
              }}
            />
          )}
        </h1>
      );
  }
}
export default ArticleTitle;
